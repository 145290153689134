import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Get the channel cover image`}</h2>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/channels/{channel_id}/design/cover.json
`}</code></pre>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with the following key-value structures.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cover image URL`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Upload cover image`}</h2>
    <pre><code parentName="pre" {...{}}>{`POST https://api.video.ibm.com/channels/{channel_id}/design/cover.json
`}</code></pre>
    <h3>{`Request`}</h3>
    <p>{`The image must be passed in the HTTP body as binary data.`}</p>
    <ul>
      <li parentName="ul">{`The image dimensions must be: 2560 x 852 px`}</li>
      <li parentName="ul">{`Supported image types:`}
        <ul parentName="li">
          <li parentName="ul">{`image/jpeg`}</li>
          <li parentName="ul">{`image/png`}</li>
          <li parentName="ul">{`image/gif`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Maximum image size: 5 MB`}</li>
    </ul>
    <p>{`CURL example:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl --location --request POST 'https://git.api.video.ibm.com.lh/channels/1234/design/cover.json' \\
--header 'Authorization: Bearer 3c2573673b782f6544405a22636f3d5d3b6f3950' \\
--header 'Content-Type: image/png' \\
--data-binary '@/path/to/your/new/cover/image.png'
`}</code></pre>
    <p>{`Note that the token in the above call is only an example.`}</p>
    <h3>{`Success response`}</h3>
    <p>{`Upon success the response body is empty with an HTTP Status code of 204.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bad_request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid input`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token could not access the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Delete cover image`}</h2>
    <pre><code parentName="pre" {...{}}>{`DELETE https://api.video.ibm.com/channels/{channel_id}/design/cover.json
`}</code></pre>
    <h3>{`Success response`}</h3>
    <p>{`Upon success, a response with HTTP status “204 No Content” is returned.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      